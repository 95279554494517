import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Img from 'gatsby-image'

import BlogStyles from '../templates/blog.module.scss'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Geht das auch nett?" />

        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          console.log(node)
          return (
            <Link className={BlogStyles.titleLink} style={{ boxShadow: `none`, fontFamily: 'sans-serif' }} to={node.fields.slug}>
            <article key={node.fields.slug}>
              <header>
                <h3
                  className={BlogStyles.indexHeader}
                >
                  
                    {title}
                  
                </h3>
                <small>{node.frontmatter.date}</small> / {node.frontmatter.author ? <small>{node.frontmatter.author}
                </small> : null}


              </header>
              {node.frontmatter.featuredImage ?
                < Img sizes={
                  node.frontmatter.featuredImage.childImageSharp.sizes
                }
                /> : null
              }

              <section>
                <p style={{ boxShadow: `none`, fontFamily: 'sans-serif', fontSize: '0.8em', }}
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}   
                />
              </section>
            </article>
            </Link>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD. MMMM, YYYY")
            title
            author
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 630, maxHeight: 300) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
